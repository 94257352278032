import { ref } from "vue";
import { defineStore } from "pinia";
import { useStorage, useToggle, useDark } from "@vueuse/core";
import { useCurrentUser } from "@/stores/user";

export interface MetaPostThread {
  discussionId: string | undefined;
  lessonId: string | undefined;
  postId: string | undefined;
}

export const useSiteStore = defineStore("site", () => {
  const pageTitle = ref();

  const simpleAlert = ref<{
    title?: string;
    content?: string;
    visible: boolean;
  }>({
    visible: false,
  });

  const isVisibleDiscussionPostThread = ref(false);

  const discussionPostThread = ref<MetaPostThread>({
    discussionId: undefined,
    lessonId: undefined,
    postId: undefined,
  });

  const courseSelectorVisible = ref(false);
  const isSubscriptionModalVisible = ref(false);
  const isDiscoveryPlatformModalVisible = ref(false);
  const isDarkMode = useStorage("isDark", false);

  const currentUser = useCurrentUser();

  const isDark = useDark({
    onChanged(dark: boolean) {
      isDarkMode.value = dark;
      currentUser.preferences = {
        ...currentUser.preferences,
        main_theme: dark ? "dark" : "light",
      };
    },
    initialValue: isDarkMode.value ? "dark" : "light",
  });

  const toggleDark = useToggle(isDark);

  function setDiscussionPostThread(metaPotThread: MetaPostThread) {
    discussionPostThread.value = metaPotThread;
  }

  return {
    courseSelectorVisible,
    pageTitle,
    simpleAlert,
    toggleDark,
    discussionPostThread,
    isVisibleDiscussionPostThread,
    isSubscriptionModalVisible,
    isDiscoveryPlatformModalVisible,
    isDarkMode: isDarkMode,
    setDiscussionPostThread,
  };
});
