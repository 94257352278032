import { inject, provide, computed } from "vue";
import { useApi } from "@/services/api";
import { useCurrentUser } from "@/stores/user";
import { useSiteStore } from "@/stores/site";

export function usePreferences() {
  const store = useCurrentUser();
  const siteStore = useSiteStore();

  const preferences = computed(() => store.preferences);

  async function recoveryLegacyData() {
    const isDirty = Object.entries(store.preferences).some(
      ([_key, value]) => !!value
    );

    if (isDirty) {
      return false;
    }

    const payload = {
      main_theme: siteStore.isDarkMode ? "dark" : "light",
      current_course: store.currentCourse?.slug,
      first_course: store.currentCourse?.slug,
      learning_path: store.area?.slug,
      platform_mode: store.platformMode,
    } as any;

    store.preferences = {
      ...payload,
    };
  }

  async function fetchPreferences() {
    const { data, error, isFetching } = await useApi(
      "student/v1/me/profile/preferences.json"
    ).json<{ preferences: Preferences }>();

    if (!error.value && data.value) {
      store.preferences = data.value.preferences;
    }

    return {
      data,
      error,
      pending: isFetching,
    };
  }

  async function updatePreferences(payload: Preferences) {
    const { data, error, isFetching } = await useApi(
      "student/v1/me/profile/preferences.json"
    )
      .put({
        preferences: {
          ...payload,
        },
      })
      .json<Preferences>();

    return {
      data,
      error,
      pending: isFetching,
    };
  }

  return {
    store,
    preferences,
    fetchPreferences,
    updatePreferences,
    recoveryLegacyData,
  };
}

export const preferencesKey = Symbol("preferences");

export function definePreferencesProvider(
  preferences: ReturnType<typeof usePreferences>
) {
  provide(preferencesKey, preferences);
}

export function useInjectablePreferences() {
  return inject(preferencesKey) as ReturnType<typeof usePreferences>;
}
