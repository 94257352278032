import { inject, type App } from "vue";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

const key = "__DAY_JS__";

export const ProvideDayjsPlugin = {
  install(app: App) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(relativeTime);
    dayjs.extend(LocalizedFormat);

    dayjs.tz.setDefault("America/Sao_Paulo");
    dayjs.locale("pt-br");

    app.config.globalProperties.$dayjs = dayjs;

    app.provide(key, dayjs);
  },
};

export function useDayjs() {
  return inject(key) as dayjs.DayjsTimezone;
}
